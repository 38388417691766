<template>
  <div class="store">
    <div class="store__top">
      <p>
        {{ value.name }}
      </p>

      <img
        v-if="!$store.state.window.to768"
        :src="value.image_url"
      >
    </div>

    <p>
      {{ value.text }}
    </p>

    <img
      v-if="$store.state.window.to768"
      :src="value.image_url"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.store {
  padding: 15px 20px 15px;
  background: #F0F2F8;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    padding: 15px 10px;
    border-radius: 10px;
  }

  .store__top {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }

    p {
      color: rgb(var(--color-dark));
      font-size: 16px;
      line-height: 100%;
      font-family: Medium;

      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }

    img {
      max-height: 26px !important;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  p {
    color: rgb(var(--text-color-light-gray));
    font-size: 12px;
    line-height: 100%;
    font-family: Medium;

    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
}
</style>
