<template>
  <div class="product">
    <img
      :src="value.image_url"
    >

    <div class="product__info">
      <p class="product__info__name">
        {{ value.name }}
      </p>

      <div class="product__info__grid">
        <div class="product__info__grid__item">
          <p class="product__info__grid__item__name">
            {{ $t('components.ui.pages.pop-up.container-info.country') }}
          </p>

          <p class="product__info__grid__item__value">
            {{ value.country }}
          </p>
        </div>

        <div class="product__info__grid__item">
          <p class="product__info__grid__item__name">
            {{ $t('components.ui.pages.pop-up.container-info.demand') }}
          </p>

          <p class="product__info__grid__item__value">
            {{ value.demand }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  width: 100%;
  margin-bottom: 10px;
  padding: 3px;
  display: flex;
  border-radius: 5px;
  background: rgb(240, 242, 248);

  @media screen and (max-width: 768px) {
    align-items: center;
  }

  @media screen and (max-width: 430px) {
    align-items: flex-start;
    flex-direction: column;
  }

  img {
    height: 80px;
    width: 80px;
    border-radius: 5px;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      height: 130px;
      width: auto;
    }

    @media screen and (max-width: 430px) {
      height: auto !important;
      width: 100% !important;
    }
  }

  .product__info {
    width: 100%;
    padding: 0 15px;

    @media screen and (max-width: 768px) {
      padding: 0 10px 10px;
    }

    @media screen and (max-width: 430px) {
      padding: 0 10px 10px !important;
    }

    .product__info__name {
      margin: 10px 0;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
      font-size: 16px;
      line-height: 100%;

      @media screen and (max-width: 768px) {
        margin: 5px 0 10px;
      }
    }

    .product__info__grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;

      .product__info__grid__item {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .product__info__grid__item__name {
          color: rgba(var(--text-color-light-gray));
          font-size: 12px;
          line-height: 100%;
          font-family: Medium;
          white-space: nowrap;
        }

        .product__info__grid__item__value {
          color: rgb(var(--color-dark));
          font-family: Medium;
          font-size: 14px;
          line-height: 100%;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
