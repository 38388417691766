<template>
  <div
    class="info"
    :style="{
      background: value.background_color
    }"
  >
    <img
      :src="value.image_url"
      class="info__image"
    >

    <div class="info__fixed">
      <div class="info__fixed__id">
        {{ value.declaration }}
      </div>

      <ClosePopUpButton
        v-if="isPopUp"
        class="info__fixed__close"
      />
    </div>

    <div class="info__start">
      <div class="info__start__info">
        <h3>
          {{ $t('components.ui.pages.pop-up.container-info.container') }} {{ value.name }}
        </h3>

        <div class="info__start__info__list">
          <div class="info__start__info__list__item">
            <div class="info__start__info__list__item__name">
              <img src="@/static/svg/store/container/income.svg">

              {{ $t('components.ui.pages.store.container-card.income') }}
            </div>

            <div class="info__start__info__list__item__value">
              {{ value.percent }}% — {{ $t(`components.ui.pages.store.container-card.income-${value.time_unit}`, { n: value.payouts_period }) }}
            </div>
          </div>

          <div class="info__start__info__list__item">
            <div class="info__start__info__list__item__name">
              <img src="@/static/svg/store/container/payouts.svg">

              {{ $t('components.ui.pages.store.container-card.payouts') }}
            </div>

            <div class="info__start__info__list__item__value">
              {{ $t(`components.ui.pages.store.container-card.payouts-${value.time_unit}`, { n: value.payouts_period }) }}
            </div>
          </div>

          <div class="info__start__info__list__item">
            <div class="info__start__info__list__item__name">
              <img src="@/static/svg/store/container/calendar.svg">

              {{ $t('components.ui.pages.store.container-card.yield-period') }}
            </div>

            <div class="info__start__info__list__item__value">
              {{ _yieldPeriod(value.time_unit, value.duration) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info__center">
      <div class="info__center__percent">
        {{ $t('components.ui.pages.store.container-card.filled') }} {{ value.filled }}%
      </div>

      <div class="info__center__progress">
        <div
          class="info__center__progress__bar"
          :style="{
            width: `${value.filled}%`
          }"
        />
      </div>
    </div>

    <div class="info__end">
      <div class="info__end__list">
        <div class="info__end__list__item">
          {{ $t('components.ui.pages.store.container-card.min-invest') }}:

          <span>
            $ {{ $format.wallet(value.minimal_invest) }}
          </span>
        </div>

        <div class="info__end__list__item">
          {{ $t('components.ui.pages.store.container-card.min-turnover') }}:

          <span>
            $ {{ $format.wallet(value.minimal_turnover) }}
          </span>
        </div>
      </div>

      <BlueButton
        v-if="isPopUp"
        tag="RouterLink"
        :to="{ name: 'StoreContainerCheckout', params: { id: value.id } }"
        :text="$t('components.ui.pages.store.container-card.buy')"
      />
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    BlueButton
  },
  props: {
    isPopUp: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    _yieldPeriod (timeUnit, duration) {
      if (timeUnit === 'day' && (duration === 365 || duration === 364)) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      } else if (timeUnit === 'week' && duration === 52) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      } else if (timeUnit === 'month' && duration === 12) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      }

      return this.$t(`components.ui.pages.store.container-card.${timeUnit}`, { n: duration })
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 25px);
    object-fit: contain;

    @media screen and (max-width: 560px) {
      display: none;
    }
  }

  &__fixed {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;

    @media screen and (max-width: 768px) {
      top: 15px;
      right: 15px;
    }

    &__id {
      padding: 3px 6px;
      background: #FFFFFF;
      border-radius: 20px;
      font-size: 12px;
      line-height: 100%;
      color: #787C8D;
      font-family: Medium;
    }

    &__close {
      display: none;

      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  &__start {
    position: relative;
    max-width: 290px;
    z-index: 2;

    &__info {
      h3 {
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;
      }

      &__list {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__name {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            line-height: 100%;
            color: rgba(32, 37, 57, .6);
            font-family: Medium;
          }

          &__value {
            font-size: 16px;
            line-height: 100%;
            text-align: right;
            color: #202539;
            font-family: SemiBold;
          }
        }
      }
    }
  }

  &__center {
    position: relative;
    z-index: 2;
    margin: 25px 0;

    &__percent {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 100%;
      color: #202539;
      font-family: Medium;
    }

    &__progress {
      height: 8px;
      width: 100%;
      background: rgba(47, 87, 233, .2);
      border-radius: 10px;
      overflow: hidden;

      &__bar {
        height: 100%;
        max-width: 100%;
        background: #2F57E9;
        border-radius: 10px;
      }
    }
  }

  &__end {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 560px) {
      flex-direction: column;
      gap: 25px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media screen and (max-width: 560px) {
        width: 100%;
      }

      &__item {
        font-size: 12px;
        line-height: 100%;
        color: rgba(32, 37, 57, .6);

        span {
          font-size: 12px;
          line-height: 100%;
          color: #202539;
          font-family: Medium;
        }
      }
    }

    a {
      max-width: 200px;

      @media screen and (max-width: 560px) {
        max-width: 100%;
      }
    }
  }
}
</style>
