<template>
  <div class="container">
    <SkeletonAnimate
      :visible="preloader"
    />

    <div
      class="container__scroll"
      ref="scroll"
    >
      <template
        v-if="data"
      >
        <ClosePopUpButton
          class="container__scroll__close black"
        />

        <Info
          :value="data"
          is-pop-up
        />

        <div
          class="container__scroll__description"
          v-html="data.text"
        />

        <div class="container__scroll__items">
          <h3>
            {{ $t('components.ui.pages.pop-up.container-info.items') }}
          </h3>

          <div class="container__scroll__items__list">
            <template
              v-for="(item, index) in data?.products"
              :key="item.id"
            >
              <ProductCard
                v-if="index < length"
                :value="item"
              />
            </template>
          </div>

          <div class="container__scroll__items__control">
            <LightButton
              v-if="!isAllProducts"
              style="max-width: 250px;"
              :text="$t('components.ui.pages.pop-up.container-info.show-more')"
              to-bottom
              loaded
              @click="length = data?.products?.length + 1, isAllProducts = true"
            />
          </div>
        </div>

        <div
          v-if="stores.length > 0"
          class="container__scroll__stores"
        >
          <h3>
            {{ $t('components.ui.pages.pop-up.container-info.our-store') }}
          </h3>

          <div class="container__scroll__stores__grid">
            <StoreCard
              v-for="item in stores"
              :key="item.id"
              :value="item"
            />
          </div>
        </div>
      </template>
    </div>

    <div
      v-if="scrollingTop && data"
      class="container__fixed"
      :style="{
        background: data.background_color
      }"
    >
      <p class="name">
        {{ $t('components.ui.pages.pop-up.container-info.container') }}

        <br>

        {{ data.name }}
      </p>

      <div
        class="container__fixed__content"
        :class="data?.color?.class"
      >
        <div class="container__fixed__content__item">
          <p class="big">
            +{{ data.percent }}%
          </p>

          <p class="sub">
            {{ $t(`components.ui.pages.store.container-card.payouts-${data.time_unit}`, { n: data.payouts_period }) }}
          </p>
        </div>

        <div class="container__fixed__content__item">
          <p class="big">
            $ {{ $format.wallet(data.minimal_invest, ',') }}
          </p>

          <p class="sub">
            {{ $t('components.ui.pages.pop-up.container-info.min') }}
          </p>
        </div>

        <BlueButton
          style="width: 100%;"
          :text="$t('components.ui.pages.pop-up.container-info.buy')"
          @click="$router.push({ name: 'StoreContainerCheckout', params: { id: data.id } })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import Info from '@/modules/store/Info.vue'
import ProductCard from '@/modules/store/ProductCard.vue'
import StoreCard from '@/modules/store/StoreCard.vue'
import LightButton from '@/ui/buttons/LightButton.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    SkeletonAnimate,
    ClosePopUpButton,
    Info,
    ProductCard,
    StoreCard,
    BlueButton,
    LightButton
  },
  data () {
    return {
      length: 6,
      isAllProducts: false,
      preloader: true,
      scrollingTop: false,
      data: null,
      stores: []
    }
  },
  mounted () {
    this.fetch()
  },
  beforeUnmount () {
    this.$refs.scroll.removeEventListener('scroll', this.scrollingEventListener)
  },
  methods: {
    async fetch () {
      try {
        const id = this.$store.state.popUp.active.id

        const [container, stores] = await Promise.all([
          this.$axios.get(`private/containers/${id}`),
          this.$axios.get('private/partner-stores')
        ])

        this.data = container.data.data
        this.stores = stores?.data?.data

        this.$refs.scroll.addEventListener('scroll', this.scrollingEventListener)
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    scrollingEventListener (e) {
      const scroll = e.target.scrollTop

      if (scroll > 300) {
        this.scrollingTop = true
      } else {
        this.scrollingTop = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: 100%;
  width: 625px;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 8px;
    border-radius: 20px 20px 0 0;
  }

  &__fixed {
    height: 70px;
    width: 100%;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 65px;
      width: 100%;
      padding: 0;
      z-index: 2;
    }

    p.name {
      margin-right: 20px;
      font-size: 18px;
      line-height: 100%;
      color: rgba(32, 37, 57, 1);
      font-family: SemiBold;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__content {
      height: 100%;
      width: 100%;
      padding: 0 15px 0 30px;
      display: flex;
      align-items: center;
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 21px, 21px 0);

      @media screen and (max-width: 768px) {
        padding: 0 17px;
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 0 0);
      }

      &__item {
        margin-right: 20px;

        p {
          line-height: 100%;
          white-space: nowrap;

          &.big {
            font-size: 16px;
            line-height: 100%;
            color: rgba(32, 37, 57, 1);
            font-family: SemiBold;
          }

          &.sub {
            font-size: 12px;
            line-height: 100%;
            color: rgba(32, 37, 57, 0.6);
            font-family: Medium;
          }
        }

        &:last-child {
          margin-right: 35px;
        }
      }

      button {
        @media screen and (max-width: 768px) {
          margin-left: 40px;
        }
      }
    }
  }

  &__scroll {
    height: 100%;
    padding: 0 30px;
    overflow: auto;
    scrollbar-color: rgba(var(--primary-color), 1) rgba(var(--primary-color), .2);
    scrollbar-width: thin;

    @media screen and (max-width: 768px) {
      padding: 10px 0 80px 0;
      scrollbar-color: rgba(var(--primary-color), 0) rgba(var(--primary-color), 0);
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: rgba(234, 238, 253, 1);
      border-radius: 4px;

      @media screen and (max-width: 768px) {
        width: 0;
        height: 0;
        background-color: rgba(var(--primary-color), 0);
      }
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(156, 163, 184, .6);
      border-radius: 4px;

      @media screen and (max-width: 768px) {
        background: rgba(var(--primary-color), 0);
      }
    }

    &__close {
      margin: 15px 0 15px auto;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__description {
      margin-top: 20px;
      color: #000000;
      font-size: 14px;
      line-height: 150%;
      font-family: Medium;
    }

    &__items {
      margin-top: 40px;

      h3 {
        margin: 20px 0;
        color: rgb(var(--color-dark));
        line-height: 100%;
        font-family: SemiBold;
      }

      &__list {
        width: 100%;
      }

      &__control {
        width: 100%;
        margin: 20px 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          @media screen and (max-width: 768px) {
            max-width: none !important;
          }
        }
      }
    }

    &__stores {
      h3 {
        color: rgb(var(--color-dark));
        line-height: 100%;
        font-family: SemiBold;
      }

      &__grid {
        margin: 30px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        @media screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
